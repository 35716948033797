// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { CodiciPagamento } from "@/models/anafrafica/codiciPagamento";
import { TextValueExtended, TextValueItem } from "../../../models/TextValueItem";
import { AnagraficaLingue } from "@/models/anafrafica/AnagraficaLingue";
import { AnagraficaFornitori } from "@/models/anafrafica/AnagraficaFornitori";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.FORNITORI;
@Component({})
export default class fornitoriPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Anagrafica.FORNITORI]; }
	tabPemessi(tab: string): boolean {
		if (tab == 'utenti')
			return this.hasPermesso(permessi.BK.Anagrafica.UTENTI);
		else
			return true;
	}
	codiciPagamento: Array<CodiciPagamento> = [];
	province: TextValueExtended[] = [];
	lingue: Array<AnagraficaLingue> = [];
	selectedTest: any = null;
	editItem: AnagraficaFornitori = new AnagraficaFornitori();
	showEditModal: boolean = false;
	currentItem: any = {};
	tipiDistributore: TextValueItem[] = [];
	showOnlyActive: boolean = false;
	selectedActionToDo: number = null;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.nominativo").toString(),
				//attributes: { style: "font-weight: bold;" }, <-- questo non funziona nella nostra cell template, ma può essere sostituitoc on la 'className'
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			},
			{ field: "email", title: this.$i18n.t("generico.email").toString(), width: this.setColPercent(15), headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "indirizzo", title: this.$i18n.t("generico.indirizzo").toString(), width: 300, hidden: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "localita", title: this.$i18n.t("generico.localita").toString(), width: 250, hidden: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{
				field: "codiceProvincia",
				title: this.$i18n.t("generico.provincia").toString(),
				width: this.setColPercent(12),
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.province,
				sortBy: "provincia",
				filterable: true,
				itemsForFilter: this.province,
				filterType: "select",
				editType: "select",
				editSource: this.province,
				editSourceValue: "code",
				hidden: true
			},
			{ field: "provincia", title: this.$i18n.t("generico.provincia").toString(), hidden: false, headerCell: "myHeaderCellTemplate", filterable: true, export: true, editHide: true },
			{ field: "cAP", title: this.$i18n.t("generico.cap").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "telefono", title: this.$i18n.t("generico.telefono").toString(), width: this.setColPercent(10), headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "fax", title: this.$i18n.t("generico.fax").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "codiceFiscale", title: this.$i18n.t("generico.codiceFiscale").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "partitaIva", title: this.$i18n.t("generico.partitaIva").toString(), width: this.setColPercent(12), headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "offsetDataMinima", title: this.$i18n.t("generico.offsetDataMinima").toString(), type: "numeric-int", headerType: "numeric-int", width: this.setColPercent(10), headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "ragioneSociale", dir: "asc" }];

	created() { }

	mounted() {
		var self = this;

		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.PROVINCE).then(res => self.province = (res as TextValueExtended[]))])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "cancellato";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function (item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	addNewRow() {
		var newItem = new AnagraficaFornitori();
		if (this.province && this.province.length > 0) {
			newItem.codiceProvincia = this.province[0].code as string;
		}

		this.onEdit(newItem);
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		self.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.ragioneSociale}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.fornitore")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: AnagraficaFornitori) {
		var self = this;

		self.editItem = Object.assign(new AnagraficaFornitori(), item);
		self.editSubTitle = "";
		self.editTitle = item.ragioneSociale;
		self.showEditModal = true;
	}

	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.fornitore") }).toString();
		self.setGridRow<AnagraficaFornitori>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false
		});
	}

}
