export class AnagraficaFornitori {
		/**
		 *
		 */
		constructor() {
			this.itemID = 0;
			this.itemGUID = "";
			this.ragioneSociale = "";
			this.indirizzo = "";
			this.localita = "";
			this.codiceProvincia = "";
			this.cAP = "";
			this.telefono = "";
			this.fax = "";
			this.codiceFiscale = "";
			this.partitaIva = "";
			this.email = "";
			this.tax_code = "";
			this.note = "";
			this.cancellato = false;
			this.offsetDataMinima = 0;
		}

        itemID: number;
        itemGUID: string;
        ragioneSociale: string;
        indirizzo: string;
        localita: string;
        codiceProvincia: string;
        cAP: string;
        telefono: string;
        fax: string;
        codiceFiscale: string;
        partitaIva: string;
        email: string;
        tax_code: string;
        note: string;
        cancellato: boolean | null;
        offsetDataMinima: number;
    }